/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '../../ui-library/src/lib/styles.scss';
@import './typography';
@import './icons';

html,
body {
  height: 100%;
  margin: 0;
  font-family: var(--font-family);
}

:root {
  --card-boarder: 6px;
  /* Colors */
  /* Brand Color */
  --brand-color: #6db193;
  --brand-color-100: #6db193;
  --brand-color-200: #6db193;
  --brand-color-300: #6db19380;
  --brand-color-400: #295F4E;
  --brand-color-600: #6db193;
  --brand-color-700: #6db193;
  --brand-color-800: #6db193;
  --brand-color-900: #6db193;

  /* Accent Color */
  --accent-color: #295f4e;
  --accent-color-100: #d1e1f0;
  --accent-color-200: #a2c4e2;
  --accent-color-300: #74a6d3;
  --accent-color-400: #4589c5;
  --accent-color-600: #125692;
  --accent-color-700: #0e406d;
  --accent-color-800: #092b49;
  --accent-color-900: #051524;

  /* Success Color */
  --success-color: #6ace81;
  --success-color-100: #e1f5e6;
  --success-color-200: #c3ebcd;
  --success-color-300: #a6e2b3;
  --success-color-400: #88d89a;
  --success-color-600: #55a567;
  --success-color-700: #407c4d;
  --success-color-800: #2a5234;
  --success-color-900: #15291a;

  /* Danger Color */
  --danger-color: #f26157;
  --danger-color-100: #fcdfdd;
  --danger-color-200: #fac0bc;
  --danger-color-300: #f7a09a;
  --danger-color-400: #f58179;
  --danger-color-600: #c24e46;
  --danger-color-700: #913a34;
  --danger-color-800: #612723;
  --danger-color-900: #301311;

  /* Warning Color */
  --warning-color: #ff8a5b;
  --warning-color-100: #ffe8de;
  --warning-color-200: #ffd0bd;
  --warning-color-300: #ffb99d;
  --warning-color-400: #ffa17c;
  --warning-color-600: #cc6e49;
  --warning-color-700: #995337;
  --warning-color-800: #663724;
  --warning-color-900: #331c12;

  /* Info Color */
  --info-color: #85c7f2;
  --info-color-100: #e7f4fc;
  --info-color-200: #cee9fa;
  --info-color-300: #b6ddf7;
  --info-color-400: #9dd2f5;
  --info-color-600: #6a9fc2;
  --info-color-700: #507791;
  --info-color-800: #355061;
  --info-color-900: #1b2830;

  /*Grayscale Color */
  --grayscale-color: #4d535f;
  --grayscale-color-100: #dbdddf;
  --grayscale-color-200: #b8babf;
  --grayscale-color-300: #94989f;
  --grayscale-color-400: #71757f;
  --grayscale-color-500: #4d535f;
  --grayscale-color-600: #3e424c;
  --grayscale-color-700: #2e3239;
  --grayscale-color-800: #1f2126;
  --grayscale-color-900: #0f1113;

  /*Primary text color */
  --primary-text-color: #494755;
  --additional-text-color: #d8d8d8;

  /* Text white */
  --text-light: #ffffff;

  /* Font family */
  --font-family: 'Open Sans';

  /* Background Color */
  --bg-background-color: #fafafa;
  --background-white: #ffffff;
  --background-summary-color: #fafbfe;
  --background-dark: #494755;

  --table-row-background: #f0f4f9;

  /* Sidebar */
  --sidebar-background-color: var(--grayscale-color-800);
  --sidebar-width: 300px;
  --top-bar-width: calc(100% - var(--sidebar-width));
  --menu-item-active-color: var(--brand-color);
  --sidebar-hover: #f8f8f8;;

  /* Border radius */
  --border-radius: 6px;

  /* Input borders */
  --border-thick: 1px;
  --border-normal: 1px;
  --input-border-default: var(--border-thick) solid var(--grayscale-color-100);
  --input-border-hover: var(--border-normal) solid var(--grayscale-color-600);
  --input-border-focus: var(--border-normal) solid var(--accent-color);
  --input-border-completed: var(--border-thick) solid var(--grayscale-color-100);
  --input-border-disabled: var(--border-thick) solid var(--grayscale-color-100);
  --input-border-error: var(--border-thick) solid var(--danger-color);

  ///* Text Properties */
  --weight-regular: 300;
  --weight-bold: 700;

  /* Heading 1 */
  --heading-1-rem: 2.074rem;
  --heading-1-size: 29px;
  --heading-1-weight: var(--weight-bold);
  --heading-1-line-height: 35;

  /* Heading 2 */
  --heading-2-rem: 1.78rem;
  --heading-2-size: 24px;
  --heading-2-weight: var(--weight-bold);
  --heading-2-line-height: 29;

  /* Heading 3 */
  --heading-3-rem: 1.44rem;
  --heading-3-size: 20px;
  --heading-3-weight: var(--weight-bold);
  --heading-3-line-height: 24;

  /* Heading 4 */
  --heading-4-rem: 1.2rem;
  --heading-4-size: 16px;
  --heading-4-weight: var(--weight-bold);
  --heading-4-line-height: 20;

  /* Body Text */
  --body-text-rem: 1rem;
  --body-text-size: 14px;
  --body-text-weight: var(--weight-regular);
  --body-text-line-height: 20;

  /* Subtitle */
  --subtitle-rem: 0.833rem;
  --subtitle-size: 12px;
  --subtitle-weight: var(--weight-regular);
  --subtitle-line-height: 15;

  /* Caption */
  --caption-rem: 0.694rem;
  --caption-size: 10px;
  --caption-weight: var(--weight-regular);
  --caption-line-height: 12;

  --src-brand-color: #a0d83f;

  font-family: var(--font-family);
  font-weight: var(--weight-regular);
  background-color: var(--bg-background-color);
}

.darkgreen-btn {
  background-color: #295f4e;
  color: #ffffff;
}

.text-btn,
.text-icon-btn {
  --border-radius: 100px;
  --primary-text-color: #fff;
  --button-height: 48px;
}

.text-btn-table .text-btn {
  --border-radius: 8px;
}

.centered-paginator .mat-paginator-container {
  justify-content: center;
}

.expander {
  .mat-form-field-appearance-outline:not(.mat-form-field-disabled)
  .mat-form-field-wrapper
  .mat-form-field-flex
  .mat-form-field-outline {
    border-radius: 20px;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0;
  }
}

.search-input .mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  border-radius: 100px;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-items-c {
  align-items: center;
}

.justify-content-c {
  justify-content: center;
}

.justify-content-sb {
  justify-content: space-between;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.pointer {
  cursor: pointer;
}

.renting_history_group {
  .mat-tab-label {
    height: 60px;
  }

  .mat-tab-label.mat-tab-disabled {
    width: 100%;
    justify-content: end;
  }
}

.renting-history-container {
  margin-top: 30px;

  .generic-table-header {
    background-color: var(--primary-text-color);
    color: white !important;

    .generic-table-header-item {
      text-transform: capitalize !important;
    }
  }

  .generic-table-data > .divider {
    .generic-table-data-row {
      border-top: 2px solid var(--primary-text-color);
    }
  }

  .generic-table-data > .divider ~ .divider {
    .generic-table-data-row {
      border-top: none;
    }
  }
}

.mail_underline {
  text-decoration: underline;
  color: var(--brand-color);
}

@media only screen and (max-width: 600px) {
  .expander {
    mat-calendar {
      width: 95vw;
      height: 10vw;
    }
  }
}


