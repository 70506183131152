@import '@angular/material/theming';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
@import './icons';

$custom-typography: mat-typography-config(
  $font-family: var(--font-family),
  $headline: mat-typography-level(26px, 35px, 700),
  $title: mat-typography-level(24px, 29px, 700),
  $subheading-2: mat-typography-level(20px, 24px, 700),
  $subheading-1: mat-typography-level(16px, 20px, 700),
  $body-1: mat-typography-level(14px, 20px, 400),
  $caption: mat-typography-level(10px, 20px, 400),
  $button: mat-typography-level(14px, 20px, 400),
  //$input: mat-typography-level(0.8px, 1.25px, 400)
);

@include mat-core($custom-typography);

/* text input */
.mat-form-field-appearance-outline:not(.mat-form-field-disabled)
  .mat-form-field-wrapper
  .mat-form-field-flex
  .mat-form-field-outline {
  background-color: var(--background-white);
  border-radius: 6px;
}

/* search component */
.search-input .mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex {
  height: 56px;

  .mat-form-field-infix {
    padding: 0.8em 0 1em 0;
  }
}

.mat-form-field-wrapper {
  padding-bottom: 0.25rem;
}

/* toast bar */
snack-bar-container.mat-snack-bar-container.hf-toast-bar {
  min-width: 25vw;
  border-radius: var(--border-radius);
  background-color: var(--background-white);
  color: var(--grayscale-color-800);
  box-shadow: 0px 4px 12px #eceef3;
}

/* autocomplete mat-option */
.mat-option {
  font-size: var(--body-text-size);
}

hf-icon-button.action-button-mobile button.icon-btn {
  border-radius: 48px;
}

/* custom slider color workaround */
.slider-primary .mat-slider .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb,
.slider-primary .mat-slider .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb-label,
.slider-primary .mat-slider .mat-slider-wrapper .mat-slider-track-wrapper .mat-slider-track-fill {
  background-color: var(--brand-color);
}

.slider-accent .mat-slider .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb,
.slider-accent .mat-slider .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb-label,
.slider-accent .mat-slider .mat-slider-wrapper .mat-slider-track-wrapper .mat-slider-track-fill {
  background-color: var(--accent-color);
}

.slider-info .mat-slider .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb,
.slider-info .mat-slider .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb-label,
.slider-info .mat-slider .mat-slider-wrapper .mat-slider-track-wrapper .mat-slider-track-fill {
  background-color: var(--info-color);
}

.slider-success .mat-slider .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb,
.slider-success .mat-slider .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb-label,
.slider-success .mat-slider .mat-slider-wrapper .mat-slider-track-wrapper .mat-slider-track-fill {
  background-color: var(--success-color);
}

.slider-warning .mat-slider .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb,
.slider-warning .mat-slider .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb-label,
.slider-warning .mat-slider .mat-slider-wrapper .mat-slider-track-wrapper .mat-slider-track-fill {
  background-color: var(--warning-color);
}

.slider-danger .mat-slider .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb,
.slider-danger .mat-slider .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb-label,
.slider-danger .mat-slider .mat-slider-wrapper .mat-slider-track-wrapper .mat-slider-track-fill {
  background-color: var(--danger-color);
}

/* custom slide toggle workaround */
.primary.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--brand-color);
}

.primary.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--brand-color-300);
}

.accent.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--accent-color);
}

.accent.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--accent-color-100);
}

.success.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--success-color);
}

.success.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--success-color-200);
}

.danger.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--danger-color);
}

.danger.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--danger-color-200);
}

.warning.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--warning-color);
}

.warning.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--warning-color-200);
}

.info.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--info-color);
}

.info.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--info-color-200);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--accent-color);
}

.mat-radio-button .mat-radio-outer-circle {
  background-color: var(--background-white);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--accent-color);
}

.mat-radio-button.mat-accent.mat-radio-disabled .mat-radio-outer-circle {
  border-color: var(--grayscale-color-200);
  background-color: var(--grayscale-color-100);
}

.mat-radio-button.mat-accent.mat-radio-disabled .mat-radio-inner-circle {
  background-color: var(--grayscale-color-200);
}

/* select item style */
mat-select .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow {
  visibility: hidden;
  border-left: 0;
  margin: 0;
}

/* menu list items */
.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content {
  width: 100%;
}

.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content:focus,
.mat-list-base .mat-list-item .mat-list-item-ripple:focus {
  outline: 0;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--brand-color);
  color: var(--brand-color);
}

/* mat-menu */
.hf-dropdown-menu .mat-menu-content {
  display: flex;
  flex-direction: column;
  min-width: 190px;
  padding: 0 10px;
}

/* sidebar */
hf-side-bar .mat-drawer-inner-container {
  overflow: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mat-select-custom-position {
  position: relative;
  top: 36px;
  left: 7px;
}

.mat-multiselect-custom-position {
  position: relative;
  top: 36px;
  left: 30px;
}

.mat-select-panel-wrap .mat-select-panel {
  min-width: calc(100% + 43px) !important;
  border-radius: 6px;
  padding: 8px;
  box-sizing: border-box;
}

.mat-option-text {
  color: var(--grayscale-color-900);
}

.custom-search .mat-form-field-outline .mat-form-field-outline-start {
  border-radius: 100px 0 0 100px;
  min-width: 100px;
}

.custom-search .mat-form-field-outline .mat-form-field-outline-end {
  border-radius: 0 100px 100px 0;
}

.search-input .custom-search .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  padding: 9px 15px;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0;
}

.mat-calendar-body-disabled.reserved-date .mat-calendar-body-cell-content {
  text-decoration: line-through;
  font-weight: bold;
  opacity: 0.5;
}
