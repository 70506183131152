@font-face {
  font-family: 'icomoon';
  src: url('../../assets/fonts/icons/icomoon.eot?f3ywk0');
  src: url('../../assets/fonts/icons/icomoon.eot?f3ywk0#iefix') format('embedded-opentype'),
    url('../../assets/fonts/icons/icomoon.ttf?f3ywk0') format('truetype'),
    url('../../assets/fonts/icons/icomoon.woff?f3ywk0') format('woff'),
    url('../../assets/fonts/icons/icomoon.svg?f3ywk0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar:before {
  content: '\e900';
}
.icon-search:before {
  content: '\e901';
}
.icon-plus_bike:before {
  content: '\e902';
  color: #4d535f;
}
.icon-paper_search:before {
  content: '\e903';
  color: #4d535f;
}
