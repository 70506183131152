@font-face {
  font-family: 'icomoon';
  src: url('assets/fonts/icons/icomoon.eot?of3eky');
  src: url('assets/fonts/icons/icomoon.eot?of3eky#iefix') format('embedded-opentype'),
    url('assets/fonts/icons/icomoon.ttf?of3eky') format('truetype'),
    url('assets/fonts/icons/icomoon.woff?of3eky') format('woff'),
    url('assets/fonts/icons/icomoon.svg?of3eky#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bobsledding_kids:before {
  content: '\e900';
}
.icon-bouldering:before {
  content: '\e901';
}
.icon-camper_accessories:before,
.icon-mountain_biking::before {
  content: '\e902';
}
.icon-camping_accessories:before {
  content: '\e903';
}
.icon-camping_furniture:before {
  content: '\e904';
}
.icon-camping_kitchen:before {
  content: '\e905';
}
.icon-child_transport:before {
  content: '\e906';
}
.icon-climbing:before {
  content: '\e907';
}
.icon-cross_country_skiing:before {
  content: '\e908';
}
.icon-e_biking:before {
  content: '\e909';
}
.icon-fishing:before {
  content: '\e90a';
}
.icon-gravelbike:before {
  content: '\e90b';
}
.icon-high_touring:before {
  content: '\e90c';
}
.icon-hockey:before {
  content: '\e90d';
}
.icon-hose_boat:before {
  content: '\e90e';
}
.icon-kayak_canoe:before {
  content: '\e90f';
}
.icon-road_biking:before {
  content: '\e910';
}
.icon-rodeling:before {
  content: '\e911';
}
.icon-roof_carrier:before {
  content: '\e912';
}
.icon-skiing:before {
  content: '\e913';
}
.icon-skitouring:before {
  content: '\e914';
}
.icon-sleeping_bags_and_mats:before {
  content: '\e915';
}
.icon-snowboarding:before,
.icon-split_board:before {
  content: '\e916';
}
.icon-snowshoeing:before {
  content: '\e917';
}
.icon-stand_up_paddle:before {
  content: '\e918';
}
.icon-tents:before {
  content: '\e919';
}
.icon-travel_bags:before {
  content: '\e91a';
}
.icon-wind_surfing:before {
  content: '\e91b';
}
.icon-date:before {
  content: '\e91c';
}
.icon-location:before {
  content: '\e91d';
}
